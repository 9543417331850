<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import TableData from "@/components/ui/MA/appraisals/TableData.vue";
import AssignUsersModal from "@/components/ui/MA/trainings/assignUsers.vue";
import AssignedUserTable from "@/components/ui/MA/trainings/AssignedUserTable.vue";
import {
  FETCH_ALL_COURSES_ACTION,
  GET_LOADING_GETTER,
  GET_COURSES_GETTER,
  GET_COURSES_TOTAL_GETTER,
  FETCH_ALL_EMPLOYEES_WITH_COURSES,
} from "@/store/modules/MA/staff-trainings/constants";

const store = useStore();

store.dispatch(`Trainings/${FETCH_ALL_COURSES_ACTION}`);
store.dispatch(`Trainings/${FETCH_ALL_EMPLOYEES_WITH_COURSES}`);

const totalCourses = computed(() => {
  return store.getters[`Trainings/${GET_COURSES_TOTAL_GETTER}`];
});
const dataForTable = computed(() => {
  return store.getters[`Trainings/${GET_COURSES_GETTER}`];
});

const loading = computed(() => {
  return store.getters[`Trainings/${GET_LOADING_GETTER}`];
});
const showAssignedUserTable = ref(false);
// const deleteAction = (event, option) => {
//   console.log(event, option);
// };

const tabledropdownOptions = [
  {
    type: "link",
    linkName: "ma-view-a-course",
    paramName: "id",
    label: "View",
  },
  // {
  //   type: "link",
  //   linkName: "ma-edit-a-course",
  //   paramName: "id",
  //   label: "Edit",
  // },
  // {
  //   type: "action",
  //   label: "Delete",
  //   action: deleteAction,
  // },
];

const tableHeader = [
  { title: "Course Name", prop: "name", id: "name" },
  { title: "Assigned", prop: "enrolled_users_count", id: "assigned" },
  { title: "Completed", prop: "completed", id: "completed" },
  { title: "Completion Rate", prop: "pass_rate", id: "pass_rate" },
  {
    title: "View More",
    prop: "user",
    id: "actions",
  },
];
const showModal = ref(false);
const modalToggler = () => {
  showModal.value = true;
};

const links = [
  {
    title: "New Course",
    icon: "local_library",
    iconClass: "material-icons-outlined",
    link: "ma-create-new-course",
    type: "link",
  },
  {
    title: "",
    icon: "",
    iconClass: "material-icons-outlined",
    link: "not-found",
    type: "action",
    action: () => {},
  },
  {
    title: "Assign Courses to Users",
    icon: "assignment_ind",
    iconClass: "material-icons-outlined",
    link: "ma-create-new-course",
    type: "action",
    action: modalToggler,
  },
  {
    title: "",
    icon: "",
    iconClass: "material-icons-outlined",
    link: "not-found",
    type: "action",
    action: () => {},
  },
  {
    title: "Employee Training Progress",
    icon: "model_training",
    iconClass: "material-icons-outlined",
    link: "not-found",
    type: "action",
    action: () => {
      showAssignedUserTable.value = !showAssignedUserTable.value;
    },
  },
];

const change = async (page) => {
  await store.dispatch(`Trainings/${FETCH_ALL_COURSES_ACTION}`, page);
};
</script>


<template>
  <div class="flex flex-col">
    <div class="my-5 px-5">
      <div class="flex justify-between gap-5 px-10">
        <template v-for="(item, index) in links" :key="item">
          <router-link v-if="item.type === 'link'" :to="{ name: item.link }">
            <div
              class="
                bg-gray-200
                w-52
                h-full
                rounded
                text-center
                py-5
                flex flex-col
                cursor-pointer
              "
            >
              <div class="flex justify-center">
                <div class="bg-white p-3 rounded-full px-4 pt-4">
                  <span
                    class="cursor-pointer text-5xl text-teal"
                    :class="item.iconClass"
                  >
                    {{ item.icon }}
                  </span>
                </div>
              </div>
              {{ item.title }}
            </div>
          </router-link>
          <div v-if="item.type === 'action'" @click="item.action">
            <div
              class=""
              :class="
                index === 1 || index === 3
                  ? 'bg-white w-52 rounded h-full text-center py-5 flex flex-col cursor-pointer'
                  : 'bg-gray-200 w-52 rounded h-full text-center py-5 flex flex-col cursor-pointer'
              "
            >
              <div class="flex justify-center">
                <div class="bg-white p-3 rounded-full px-4 pt-4">
                  <span
                    class="cursor-pointer text-5xl text-teal"
                    :class="item.iconClass"
                  >
                    {{ item.icon }}
                  </span>
                </div>
              </div>

              {{ item.title }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <template v-if="showAssignedUserTable">
      <AssignedUserTable />
    </template>
    <template v-else>
      <div class="flex-1 overflow-y-auto">
        <p class="text-gray-600 font-medium pl-5 text-2xl ml-3">
          Assigned Employee Courses
        </p>
        <div class="px-5">
          <TableData
            :loading="loading"
            :dropdownOptions="tabledropdownOptions"
            :tableData="dataForTable"
            :tableHeader="tableHeader"
            :rowClass="'text-center'"
          />
          <div v-if="dataForTable.length > 0" class="flex justify-center my-5">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalCourses"
              @current-change="change"
            >
            </el-pagination>
          </div>
          <AssignUsersModal v-model:show="showModal" :courses="dataForTable" />
        </div>
      </div>
    </template>
  </div>
</template>
