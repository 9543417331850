<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { useStore } from "vuex";
import SearchBox from "@/components/layout/RE/policies/searchbox.vue";
import {
  GET_COURSES_TOTAL_GETTER,
  FETCH_ALL_COURSES_ACTION,
  ASSIGN_A_USER_COURSES,
} from "@/store/modules/MA/staff-trainings/constants";
import Swal from "sweetalert2";

const store = useStore();
const emits = defineEmits(["update:show"]);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  courses: {
    type: Array,
    default: () => [],
  },
});

const showModalComputed = computed({
  get() {
    return props.show;
  },
  set(value) {
    emits("update:show", value);
  },
});

const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});

const coursesSelected = ref([]);
const user = ref(null);
const submit = async () => {
  await store.dispatch(`Trainings/${ASSIGN_A_USER_COURSES}`, {
    user: user.value?.id,
    courses: coursesSelected.value.map((item) => ({ course: item })),
  });
  coursesSelected.value = [];
  Swal.fire({
    title: "Done",
    text: "successfully Assigned Courses to Selected User",
    icon: "success",
  });
  emits("update:show", false);
};

const totalCourses = computed(() => {
  return store.getters[`Trainings/${GET_COURSES_TOTAL_GETTER}`];
});

const userSelected = async (e) => {
  user.value = e;
};

const change = async (page) => {
  await store.dispatch(`Trainings/${FETCH_ALL_COURSES_ACTION}`, page);
};
</script>
<template>
  <base-modal v-model:show="showModalComputed" @confirm="submit">
    <template #title>Assign A Course to User</template>
    <template #content>
      <div class="flex gap-5">
        <div class="flex-grow">
          <SearchBox
            :secondaryOptions="getRoles"
            :layout="'vertical'"
            :hired="true"
            class="w-full shadow-md px-5"
            :title="'Search User'"
            @user-selected="userSelected"
          />
        </div>
        <div class="flex-grow overflow-x-scroll">
          <p class="text-xl font-medium">Courses</p>
          <div class="flex-col">
            <div class="flex" v-for="item in courses" :key="item.id">
              <input
                v-model="coursesSelected"
                type="checkbox"
                name="course"
                :id="item.id"
                :value="item.id"
              />
              <label :for="item.id" class="ml-2 cursor-pointer">
                {{ item.name }}
              </label>
            </div>
            <div class="flex justify-center my-5">
              <el-pagination
                background
                layout="pager"
                :total="totalCourses"
                @current-change="change"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #successButton> Save </template></base-modal
  >
</template>
