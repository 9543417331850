<script setup>
// import BaseSpinner from "../../../../ui/baseComponents/BaseSpinner.vue";
import { computed, ref, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";

const store = useStore();
const emits = defineEmits(["user-selected"]);

const searchResponse = ref([]);
const loading = ref(false);
const currentUser = ref(null);
const filters = [
  {
    customLabel: "Role",
    value: "role",
  },
  {
    customLabel: "Email",
    value: "email",
  },
  {
    customLabel: "Last Name",
    value: "last_name",
  },
  {
    customLabel: "Mobile Phone",
    value: "mobile_phone",
  },
];
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  layout: {
    type: String,
    default: "vertical",
  },
  secondaryOptions: {
    type: [Array, Object],
    required: true,
  },
  hired: {
    type: Boolean,
    default: false,
    required: false,
  },
});
const inputSearchValue = ref("");
const filterSelection = ref(null);
const placeholderValue = computed(() => {
  const placeholder =
    filterSelection.value?.value === "email"
      ? "Enter individuals email address..."
      : filterSelection.value?.value === "last_name"
      ? "Enter individuals last name address..."
      : filterSelection.value?.value === "mobile_phone"
      ? "Enter individuals mobile Number address..."
      : "";
  return placeholder;
});
const submitSearch = async () => {
  loading.value = true;
  const filter = {
    [filterSelection.value.value] : inputSearchValue.value.code
      ? inputSearchValue.value.code
      : inputSearchValue.value,
      is_hired : props.hired ? 1 : 0,
  };
  const { data } = await store.dispatch("REgeneralModule/loadUsers", filter);
  searchResponse.value = data;
  loading.value = false;
};
const resetSearch = () => {
  filterSelection.value = null;
  inputSearchValue.value = "";
  searchResponse.value = [];
};
</script>
<template>
  <div class="flex flex-col py-5 rounded-xl text-grey justify-center">
    <span class="self-start text-2xl font-bold my-5">{{ props.title }}</span>
    <div
      class="flex gap-y-5 mb-3 items-center"
      :class="[
        { 'flex-col': props.layout === 'vertical' },
        { 'flex-row': props.layout === 'horizontal' },
      ]"
    >
      <form class="flex-grow w-full" @submit.prevent="submitSearch">
        <div class="flex-grow gap-y-4 w-full">
          <v-select
            @option:selected="inputSearchValue = ''"
            v-model="filterSelection"
            class="filterDropDown w-full"
            placeholder="Search By"
            label="customLabel"
            :options="filters"
          ></v-select>

          <v-select
            class="w-full my-4"
            v-if="filterSelection?.value === 'role'"
            :options="secondaryOptions"
            label="label"
            :filterable="true"
            :placeholder="'Please select...'"
            v-model="inputSearchValue"
          >
          </v-select>

          <input
            v-else
            :placeholder="placeholderValue"
            v-model="inputSearchValue"
            type="text"
            class="border rounded-md p-2 w-full my-4"
          />
        </div>
      </form>

      <div
        :class="{ 'flex-col': props.layout === 'horizontal' }"
        class="flex justify-center items-center"
      >
        <base-button
          class="mb-2 mt-0"
          backgroundColour="bg-orange hover:bg-orange-light"
          @click="resetSearch"
          >Reset</base-button
        >
        <base-button class="mb-2 mt-0" @click="submitSearch"
          >Search</base-button
        >
      </div>
    </div>
    <template v-if="searchResponse.length">
      <ul class="list-disc ml-5">
        <li
          v-for="user in searchResponse"
          :key="user.id"
          class="hover:underline cursor-pointer flex items-center"
          @click="
            () => {
              currentUser = user;
              emits('user-selected', user);
            }
          "
        >
          {{
            `${user.profile?.first_name} ${user.profile?.last_name} (${user.email})`
          }}
          <span
            class="material-icons mr-1 cursor-pointer"
            v-if="user.id === currentUser?.id"
          >
            check
          </span>
        </li>
      </ul>
    </template>
    <base-spinner v-if="loading" />
  </div>
</template>
