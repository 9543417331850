<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import TableData from "@/components/ui/MA/appraisals/TableData.vue";
import {
  GET_ALL_EMPLOYEES_WITH_COURSES_GETTER,
  GET_LOADING_GETTER,
  FETCH_ALL_EMPLOYEES_WITH_COURSES,
} from "@/store/modules/MA/staff-trainings/constants";

const store = useStore();
const tabledropdownOptions = [
  {
    type: "link",
    linkName: "ma-view-a-course",
    paramName: "id",
    label: "View",
  },
];
const loading = computed(() => {
  return store.getters[`Trainings/${GET_LOADING_GETTER}`];
});
const employee_with_courses = computed(() => {
  return store.getters[`Trainings/${GET_ALL_EMPLOYEES_WITH_COURSES_GETTER}`]
    ?.data;
});
const totalCourses = computed(() => {
  return store.getters[`Trainings/${GET_ALL_EMPLOYEES_WITH_COURSES_GETTER}`]
    ?.total;
});

const tableHeader = [
  { title: "Employee Name", prop: "user", id: "fullnameWithoutUser" },
  { title: "Type of Employee", prop: "profile.primary_role", id: "type" },
  { title: "Location", prop: "profile.city", id: "location" },
  { title: "Courses Assigned", prop: "courses_count", id: "courses_count" },
  { title: "Completion Status", prop: "status", id: "status" },
  {
    title: "Options",
    prop: "user",
    id: "actions",
  },
];

const change = async (page) => {
  await store.dispatch(`Trainings/${FETCH_ALL_EMPLOYEES_WITH_COURSES}`, page);
};

</script>
<template>
  <div class="flex-1 overflow-y-auto">
    <p class="text-gray-600 font-medium pl-5 text-2xl ml-3">
      Assigned Employee Courses
    </p>
    <div class="px-5">
      <TableData
        :loading="loading"
        :dropdownOptions="tabledropdownOptions"
        :tableData="employee_with_courses"
        :tableHeader="tableHeader"
        :rowClass="'text-center'"
      />
      <div class="flex justify-center my-5">
        <el-pagination
          background
          layout="pager"
          :total="totalCourses"
          @current-change="change"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>