<script setup>
import { defineProps, defineEmits } from "vue";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
import moment from "moment";

const emits = defineEmits(["rowId"]);

const setterId = (value) => {
  console.log("row id: ", value);
  emits("rowId", value);
};

const props = defineProps({
  loading: {
    type: Boolean,
    requried: true,
  },
  tableHeader: {
    type: Array,
    required: true,
  },
  dropdownOptions: {
    type: Array,
    required: true,
  },
  tableData: {
    type: [Array, Object],
    required: true,
  },
  rowClass: {
    type: String,
    default: "",
  },
});

const circumference = 30 * 2 * Math.PI;
</script>
<template>
  <template v-if="!props.loading || props.tableData?.length">
    <el-table
      header-row-class-name="bg-gray-200"
      header-cell-class-name="text-teal py-5 align-top"
      :cell-style="rowClass && { padding: '10px' }"
      :row-class-name="rowClass && `border-t ${props.rowClass}`"
      class="
        w-full
        rounded-xl
        overflow-hidden
        bg-gray-100
        flex flex-grow
        h-full
      "
      :data="props.tableData?.length ? props.tableData : []"
      :default-sort="{ prop: 'date', order: 'descending' }"
      style="width: 100%"
    >
      <el-table-column
        v-for="item in props.tableHeader"
        :key="item.index"
        :prop="item.prop"
        :label="item.title"
        class="text-center"
        :sortable="item.id != 'actions' ? true : false"
      >
        <template #default="scope">
          <div v-if="item.id === 'rolesArray'">
            <span v-for="(role, index) in scope.row.roles" :key="index">
              {{ role.name }}
              {{ index !== scope.row.roles.length - 1 ? "," : "" }}
            </span>
          </div>
          <div v-if="item.id === 'moduleslength'">
            {{ scope.row.modules.length }}
          </div>

          <div v-if="item.id === 'preparation_form'">
            {{ moment().format("ll") }}
          </div>
          <div v-if="item.id === 'follow_up_schedule'">
            {{ moment().format("ll") }}
          </div>
          <div v-if="item.id === 'appraisal_form'">
            <p class="text-teal font-medium">File.pdf</p>
          </div>
          <div v-if="item.id === 'follow_up_form'">
            <p class="text-teal font-medium">File.pdf</p>
          </div>
          <div v-if="item.id === 'goals'">
            <p class="">Goals/Objectives</p>
          </div>
          <div v-if="item.id === 'department'">
            {{ scope.row.department.name }}
          </div>
          <div v-if="item.id === 'location'">
            Rushden Medical<br />
            Center
          </div>
          <div v-if="item.id === 'assigned_by'">Assigned By</div>
          <div v-if="item.id === 'assigned_on'">
            {{ moment().format("ll") }}
          </div>
          <div v-if="item.id === 'due_date'">{{ moment().format("ll") }}</div>
          <!-- To-do -->
          <div v-if="item.id === 'completed'">3</div>
          <div v-if="item.id === 'pass_rate'">50%</div>
          <div v-if="item.id === 'av_completion_time'">4 days</div>
          <div class="" v-if="item.id === 'statusCourse'">In-progress</div>

          <div class="" v-if="item.id === 'status'">
            <div
              class="
                inline-flex
                items-center
                justify-center
                overflow-hidden
                rounded-full
                bottom-5
              "
            >
              <!-- Building a Progress Ring: https://css-tricks.com/building-progress-ring-quickly/ -->
              <svg class="w-10 h-10">
                <circle
                  class="text-gray-300"
                  stroke-width="3"
                  stroke="gray"
                  fill="transparent"
                  r="10"
                  cx="20"
                  cy="20"
                />
                <circle
                  class="text-orange"
                  stroke-width="3"
                  :stroke-dasharray="circumference"
                  :stroke-dashoffset="
                    circumference - (10 / 100) * circumference
                  "
                  stroke-linecap="round"
                  stroke="orange"
                  fill="transparent"
                  r="10"
                  cx="20"
                  cy="20"
                />
              </svg>
              <span class="text-sm text-gray-700">30%</span>
            </div>
          </div>
          <div class="" v-if="item.id === 'fullnameWithoutUser'">
            {{
              scope.row.profile.first_name + " " + scope.row.profile.last_name
            }}
          </div>
          <div class="" v-if="item.id === 'fullname'">
            {{
              scope.row.user.profile.first_name +
              " " +
              scope.row.user.profile.last_name
            }}
          </div>
          <div class="" v-if="item.id === 'date'">
            {{ moment(new Date(scope.row.created_at)).format("ll") }}
          </div>
          <div v-if="item.id === 'actions'" @click="setterId(scope.row.id)">
            <table-options-dropdown
              :id="scope.row.id"
              :options="props.dropdownOptions"
              :dynamicParam="scope.row.id"
            >
              <span class="material-icons"> more_horiz </span>
            </table-options-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </template>
  <template v-else>
    <table-skeleton />
  </template>
</template>
<style>
</style>